import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

export default function AddPS() {


    let navigate = useNavigate();
    const [res, setRes] = useState([]);
    const type = sessionStorage.getItem('Type');
    console.log(type)
    
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
       // let mail = sessionStorage.getItem('Mail')
       // setMailId(mail);

        if (authToken) {
            if(type!=="Coordinator")
            navigate('/home')
        }

        if (!authToken) {
            navigate('/login')
        }
    }, [])

  return (
    <div style={{height:'100vh'}}>
        Will be Updated Soon...!
    </div>
  )
}
