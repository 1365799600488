

import { doc, getDoc,updateDoc } from "firebase/firestore"; 

import { getDb } from "./db.mjs"

const collection_name = "Participants"

export const updateS = async id => {
   
   // initialize Firestore

    const docRef = doc(getDb(), "Participants", id);
    
    const data = {
      status: 3
    };
    
    updateDoc(docRef, data)
    .then(docRef => {
        console.log("A New Document Field has been added to an existing document");
        return 1;
    })
    .catch(error => {
        console.log(error);
        return 0;
    })
}