import  { Component } from 'react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {findOne} from '../services/timeline.mjs'
import { useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCardTitle, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import { Button, Row,Col } from 'react-bootstrap';
import TeamCard from './TeamCard.js';


export default function Dashboard() {

    
    let navigate = useNavigate();
    const [res, setRes] = useState([]);
    const type = sessionStorage.getItem('Type');
    //console.log(type)
    
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
       // let mail = sessionStorage.getItem('Mail')
       // setMailId(mail);

        if (authToken) {
            navigate('/dashboard')
        }

        if (!authToken) {
            navigate('/login')
        }
    }, [])

    
    
    const fetchData = async () => {
       // setLoading(true)

    //   console.log(mailId);
        const resData = await findOne(sessionStorage.getItem('Mail').toString());
       // console.log(sessionStorage.getItem('Mail').toString())
        
        
       // const documentSnapshot = await getDoc(chartReference);

      //  let data = {};

      //  docChartData = res.p1;

        // stores ordersGraph array into the state variable, chartData
       setRes(resData);
       console.log(resData)
       sessionStorage.setItem('upload',resData["status"])
       sessionStorage.setItem('tId',resData.teamId)
       console.log(res["status"])
        
      //  resForm.name = resData.name;
       // console.log(resData)
        
        
       // setLoading(false)
    }


    useEffect(() => {
      if(type==="Participant"){
        fetchData()
      }
       
    }, [])



  return (
    <div style={{}}> 
    
        <div style={{height:type==="Participant"?null:0,overflow:'hidden',marginTop:20}}>


<MDBContainer className="my-5" >

<MDBCard>
  <MDBRow className='g-0'>


  <div className="badge bg-primary text-wrap" style={{width: "100%",fontSize:14,alignContent:'center',alignItems:'center',margin:'auto',marginBottom:20}}>
    
   
    <div className="badge  text-wrap" style={{fontSize:12}}> Problem Statement: {res.PS}</div>
  
  <a href='/problemStatements'><div className="badge bg-black text-wrap"  style={{fontSize:12,float:'right'}}>Browse</div></a>
  </div>

  
  </MDBRow>

 
  <MDBRow className='g-0'> 
  <div className="badge text-wrap" style={{width: "100%",fontSize:20,alignContent:'center', alignItems:'center',margin:'auto',backgroundColor:res.status!=1?"green":"gray"}}>
    
   
    <div className="badge  text-wrap" style={{fontSize:14}}>  {res.status==1?"Status: Your Problem Statement is under Review":"Volunteer: "+res.vol}</div>
    <div className="badge  text-wrap" style={{fontSize:14}}>Team Id: {res.teamId }</div>
 
  </div>
  <div className="badge text-wrap" style={{width: "100%",fontSize:20,marginTop:10 ,alignContent:'center', alignItems:'center',margin:'auto',backgroundColor:"red"}}>
    
   
    <div className="badge  text-wrap" style={{fontSize:14}}>Last Date to Upload Poster is 13th April 2k23</div>
    <div className="badge  text-wrap" style={{fontSize:14,visibility:res.status==3?"visible":"hidden"}}>You have Successfully Uploaded your Poster Thank You..!</div>
  </div>
  <div className="badge text-wrap" style={{visibility:res.status==3 ||res.status=="3"?"visible":"hidden",width: "100%",fontSize:20,marginTop:10 ,alignContent:'center', alignItems:'center',margin:'auto',backgroundColor:"green"}}>
    
     <div className="badge  text-wrap" style={{fontSize:14}}>You have Successfully Uploaded your Poster Thank You..!</div>
  </div>
  </MDBRow>


  </MDBCard>
  </MDBContainer>
  <Button href='https://drive.google.com/uc?export=view&id=1RcADwPa0CYP9ZVkqScW7v5wRrDFhOpb3' style={{marginBottom:10,visibility:res.status==3?'hidden':'visible'}}>Download Poster Templete</Button>
<br/>
  <Button href='/uploadPS' style={{marginBottom:10,marginRight:10,visibility:res["status"]==3||res["status"]==="3"?'hidden':'visible',overflow:'hidden'}}>Upload Poster</Button>

<MDBCardTitle style={{marginTop:10}}>Team Details</MDBCardTitle>
<div className='d-lg-none'>

<TeamCard id={1} {...res}/>

<TeamCard  id={2} {...res.p1}/>
<TeamCard id={3} {...res.p2}/>
<TeamCard id={4} {...res.p3}/>


  
</div>

<div className='d-none d-lg-block' style={{alignContent:'center',whiteSpace:'nowrap',alignItems:'center',overflowX:'scroll',overflowY:'hidden',width:'100%'}}>
 



  <Row>

  <Col>

<TeamCard id={1} {...res}/>
</Col>
<Col>
<TeamCard  id={2} {...res.p1}/>
</Col>

</Row>
<Row>
<Col>
<TeamCard id={3} {...res.p2}/>
</Col>
<Col>
<TeamCard id={4} {...res.p3}/>
</Col>
</Row>

</div>


</div>

<div style={{height:type==="Coordinator"?'100vh':0,overflow:'hidden'}}>


<div className="badge bg-primary text-wrap" style={{fontSize:17,alignContent:'center',alignItems:'center',margin:'auto',marginBottom:10}}>
    
<div className="badge  text-wrap" style={{fontSize:18}}> Hello {sessionStorage.getItem("cName")} ...!</div>

</div>
<br/>

<div className="badge bg-primary text-wrap" style={{float:'right', fontSize:17,alignContent:'center',alignItems:'center',margin:'auto',marginBottom:20,marginRight:20}}>
    
<div className="badge  text-wrap" style={{fontSize:20}}> Your Id: {sessionStorage.getItem("cId")}</div>

</div>

<br/>
<br/>
<br/>
<br/>


<div>
  <Button style={{margin:10,paddingRight:50,paddingLeft:50,fontSize:18}} href='/addTeam'>Add Team</Button>
  <Button style={{margin:10,paddingRight:10,paddingLeft:10,fontSize:18}}  href='/addPS'>Add Problem Statement</Button>
</div>


</div>



    </div>
  )
}
