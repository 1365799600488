import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 

let db = false;

export const getDb = () => {
    if(!db){
        const firebaseConfig = {
            apiKey: "AIzaSyAwmIf5O_7OC4Lu1traiC6tR1v-lPpsa3M",
            authDomain: "openhouse-vnr.firebaseapp.com",
            projectId: "openhouse-vnr",
            storageBucket: "openhouse-vnr.appspot.com",
            messagingSenderId: "967448319270",
            appId: "1:967448319270:web:bd3acb9c4a6588a2809de7",
            measurementId: "G-3PK22TKFKS"
        }

        const app = initializeApp(firebaseConfig)

        db = getFirestore(app)
        
    }

    return db
}