import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { findOTP } from '../services/otpget.mjs';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';


export default function AdminVerify() {

    let navigate = useNavigate();
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
  
        if (!authToken) {
            navigate('/login')
        }
  
       
    }, [])


    var inputs = document.querySelectorAll('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) { 
        inputs[i].addEventListener('keydown', function(event) { 
            if (event.key==="Backspace" ) {
                 inputs[i].value='' ; if (i !==0) 
                 inputs[i - 1].focus(); 
                } 
                 else { if (i===inputs.length - 1 && inputs[i].value !=='' ) { 
                    return true; 
                } else if (event.keyCode> 47 && event.keyCode < 58) { 
                    inputs[i].value=event.key; if (i !==inputs.length - 1) inputs[i + 1].focus(); 
                    event.preventDefault(); } else if (event.keyCode> 64 && event.keyCode < 91) { 
                        inputs[i].value=String.fromCharCode(event.keyCode); if (i !==inputs.length - 1) inputs[i + 1].focus(); 
                    event.preventDefault();
                 }
                 }
                 }); 
                } 

  return (
    <div style={{height:'100vh', margin:'auto', alignContent:'center',alignItems:'center',marginTop:40}}>
           
        <div class="card3 p-2 text-center">
            <h6>Enter the OTP <br/> to verify your Role</h6>
            <div> <span>-Team Open House</span> <small></small> </div>
            <div  id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> 
            <input class="m-2 text-center form-control rounded" type="text" id="first" maxlength="1" /> 
            <input class="m-2 text-center form-control rounded" type="text" id="second" maxlength="1" /> 
            <input class="m-2 text-center form-control rounded" type="text" id="third" maxlength="1" />
             <input class="m-2 text-center form-control rounded" type="text" id="fourth" maxlength="1" /> 
             <input class="m-2 text-center form-control rounded" type="text" id="fifth" maxlength="1" /> 
             <input class="m-2 text-center form-control rounded" type="text" id="sixth" maxlength="1" />
              </div>
            
            

            <div class="mt-4"> <button class="btn btn-danger px-4 validate" onClick={verifyOtp}>Validate</button> </div>


        </div>
   
   <ToastContainer/>
   
</div>
  
  )

  
    async function verifyOtp() {
        const inputs = document.querySelectorAll('#otp > *[id]');
       var  otp=''
       for(let i=0;i<inputs.length;i++){

        if(inputs[i].value!==" ")
        otp+=inputs[i].value;
       }

       if(otp.length ==6){
       // console.log(otp);

       var res = await findOTP(sessionStorage.getItem("Mail"));
       if(res.otp.toString() === otp){
        toast.success('Login Success');
        sessionStorage.setItem("Type","Coordinator");
        sessionStorage.setItem("cName",res.name);
        sessionStorage.setItem("cId",res.aID);
        navigate("/dashboard");
        
        
       }

       }
       else{
        toast.error('Please Enter Valid OTP');
       }
    }


   

//});
}
