import React, { Component } from 'react'
import PSCard from './PSCard';
import Footer from './Footer/Footer';




const ps =[
  {
   "PS": "Precision Weed Detection In The Agricultural Field Using Aerial Imagery ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "VLSI Implementation of Data Encryption and Decryption Cryptography Using AES and Reversible LFSR",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "A Hi-Fi wall mount which can charge a smartwatch.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "A machine that saves time to do writing and drawing .It can be also be upgraded for PCB printing",
   "Clg": "AAR MAHAVEER ENGINEERING COLLEGE ",
   "Type": 0
  },
  {
   "PS": "A Novel Approach for Real Time Traffic Prediction using Deep Learning in VANET",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "A QPSK Hybrid Downlink NOMA With OFDM  and OFDM-IM For Beyond 5G Applications",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "A startup idea based on streetfood",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "A study on biodegradation of polypropylene by using co2 evolution test",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Abnormal activity detection in recorded and live surveillance ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Advanced Dry Waste Management System through Machine Learning and Blockchain technologies ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Aerial image based plant leaf disease detection and classification ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "AI controlled Robotic Arm",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Air purifier ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "An Adaptive Hybrid Beamforming Approach for  5G-MIMO mmWave Wireless Cellular Networks",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Analysis of soil, waterbodies, vegetation and manually identifying the correct crops to grow in a particular location is a tedious task. So it can be simplified with the help of remote sensing, by which we can easily provide crop growth and disease prediction dynamically by choosing a particular area in a given map.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "APD(Avalanche Photodiode) Based LASER Range-Gating System",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Asic design implementation of staircase encoder",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Automatic hand gesture recognition in real time",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Automatic solar tracker ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Automatic solar tracker ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Bolt Loosening Detection Using Deep Learning ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Brain Tumor Detection And Classification Using Deep Learning",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Building A Blockchain System To Store Student’s College Certificates ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Cardiovascular Disease (CVD) is the major reason for increase in death ratio, for the most part, alluding to conditions that include limited or blocked veins that can prompt a heart attack or stroke.  The machine learning classifier predicts the ailment dependent on the state of the side effect endured by the patient. Machine learning tree classifiers like Decision Tree, Logistic Regression, Support vector machine (SVM) and K-Nearest Neighbor  (KNN) were breaking down dependent on their precision accuracy  and it finds whether a patient has cardiovascular disease or not.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "CATTLE HEALTH MONITORING SYSTEM",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Channel estimation in MIMO OFDM systems",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Chatgpt base model architecture,which generates stories based on user selected author ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "College students often face challenges in finding like-minded peers within their college who share their skills and interests and can collaborate on projects. The lack of a platform to connect and share skills makes it difficult for them to explore their potential and gain valuable experience. The proposed website aims to provide a solution to this problem by providing a platform for students to connect, collaborate, and share their skills, projects, and interests.",
   "Clg": "Chaitanya Bharathi Institute of Technology ",
   "Type": 1
  },
  {
   "PS": "COMPARATIVE ANALYSIS OF IMAGE TO IMAGE TRANSLATION USING PIX2PIX GAN",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Comparative Performance Analysis of MapReduce and Apache Spark using Supervised and Unsupervised Algorithms",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "COMPUTATION RESOURCE ALLOCATION USING  NOMA TECHNIQUE",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Contactless smart dustbin ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Conventional Voltage Controlled Oscillators have a low tuning range, less band of  operating frequency, and more delay. To reduce these constraints in VCO for Phase Locked  Loop(PLL), a new architecture called Current Starved Negative Skewed Voltage Controlled  Oscillator (CSNSVCO) is designed that has a feedback path which contains current starved  inverters in it",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Covid -19 affects our daily life, public health, and the global economy have been devastating. It is crucial to find positive cases as soon as feasible to stop the pandemic from spreading further and to prevent another outbreak, also to treat the sick people as soon as possible. Its effects on daily life, public health, and the global economy have been devastating. It is crucial to find positive cases as soon as feasible to stop the pandemic from spreading further and to prevent another outbreak, also to treat the sick people as soon as possible. RT-PCR is the test usually taken but it has lesser accuracy, we have built a model which classifies Covid, Normal,and Pneumonia diseased by processing lung X- Rays using Capsule Neural Network.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Deep learning based fruit damage detection",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Deep learning based knee osteoarthritis classification ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Delay and Power Efficient Systolic FIR Architecture for  Noise Suppression of EOG Signal",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "DESIGN AND ANALYSIS OF CMOS BASED BINARY SCALED DIGITAL TO ANALOG CONVERTER ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Design and Development of Breast Cancer Detection and Classification system using Deep learning",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Design and fabrication of women safety device which is used to eradicate women rapes",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Design and Implementation of ADALM Pluto SDR Transceiver using MATLAB and Simulink",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Design and implementation of high speed binary vounters using sorting network and one hot code generation technique",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Design and Verification of Ethernet Protocol ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Design of Dual Band MIMO Antenna for 5G applications",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "designing the better multiplier in terms of power delay product using hybrid adder",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "desktop assistant",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Detection  of Cardiomegaly using CNN model from CXR&nbsp;images",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Detection of Moonlighting using Keystroke Dynamics",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "DEVELOPMENT OF A SPEECH-BASED DEPRESSION DETECTION SYSTEM",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Driver Anti sleep alaram device ",
   "Clg": "Gitam university Hyderabad ",
   "Type": 0
  },
  {
   "PS": "EEG based thoughts to text converter",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Electric cycle for easy mobility in heavy traffic. ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Electricity re-generation on principle of electro-magnetic induction &amp; solenoid engine. For recharging in battery in ev's while running.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "ENERGY EFFICIENCY AND SPECTRUM EFFICIENCY FOR STATIONARY CARS IN VEHICULAR NETWORKS",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "ENERGY EFFICIENT ROBUST TREE BASED ROUTING PROTOCOL FOR WIRELESS SENSOR NETWORKS",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Error detection and correction code using Improved Redundant Matrix Code Algorithm ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "ESTIMATION OF NITROGEN IN PLANTS USING MACHINE LEARNING TECHNIQUES",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Estimation of time delay in multi carrier DSSS multipath transmissions for 5G networks",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Fading has always been a part and parcel of the wireless communication and so it is a major concern for any type of wireless system. Various fading channels like Rayleigh, Rician, Lognormal, Nakagami-m channel channel will be investigated to know where the performance of the detector will be optimum.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Fake News Detection using GNN",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Fraudulent Transaction Detection System",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Gene classification based on DNA sequence using Machine learning and deep learning",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Gene family classification based on DNA sequence using Machine learning and deep learning ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Generalized deep learning model for gastric, colon and renal cancer",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Given code in C,C++ it converts the code into Python",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Identification and display of depth of rainfall through mobile application including temperature and humidity using tipping bucket rain gauge station and sensors",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Image Forgery Detection",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Implementation Of AES Algorithm",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Implementation of high performance 64 bit mac unit",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Implementation of Square Root Function using Vedic Mathematics",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Improving energy efficiency in wireless body area network",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "In many applications, it is necessary to keep track of the number of people entering, leaving the place (malls, institutions).",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Individuals who are paralyzed, have recently had surgery, or have unusual disorders such as Quadriplegia and Locked-In syndromes may be unable to move their bodies and have difficulties while communicating. Hence, it is necessary to have an Application that can convey their basic needs to the caretaker.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "INTEGRATION OF MLOPS WITH IOT",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "IOT based Coal mine safety monitoring and alerrting system",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "IOT BASED PATIENT HEART MONITORING AND ABNORMALITY DETECTION",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "lora based co2 monitoring network system for contagion prevention by controlled ventilation",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Lung disease identification Using chest scans",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Machine learning Algorithms for crop yield prediction",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "MBTI(Myers Briggs Type Indicator) based personality classification",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Meaningful Dynamic Telugu Lyric Generation and Instrument Detection using NLP and ML",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Meteorites prediction and analysis ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Mid-day meals",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Millions of students have a learning, physical,or visual  disability that prevents them from reading conventional print.This makes the  majority of educational materials for science, technology, engineering and math(STEM) fields inaccessible to them .What if machine learning could help bridge that gap? ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Mis diagnosis of respiratory diseases.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Multiclass Classification of Lung Cancer Detection Using Hybrid CNN model",
   "Clg": "VNR VJIET",
   "Type": 1
  },
 
  {
   "PS": "OSTEOARTHRITIS KNEE REPLACMENT DETECTION",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Overcoming limitations of existing electric motorcycles using Hydrogen Fuel Cell Technology",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Overcoming limitations of the existing electric motorcycles with Hydrogen Fuel Cell Technology",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Performance Analysis of Stable Election protocol with Energy efficiency and Network Longevity",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Performance Evaluation of Conformal Patch array Antenna on cylindrical surface",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Personalized health monitoring system with wireless stethoscope ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Real time implementation of steganography ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Robotic arm",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Secure E-Voting using Blockchain ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Separation of Speech in Multitalker Environment ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Sign Recognisation Robotic Vehicle Using Raspberry pi ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "SMART DUSTBIN",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Sound Honking of a vehicle &amp;  App Development for Automotives",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Systems that can interpret text descriptions to build an image allows humans to visually express their thoughts without words. The goal is to develop a system that will generate images based on the text descriptions provided by the user. With a manifold of increasing applications in real time from interior designing, fashion designing to art generation and photo editing, the most taxing part is to produce a model trained on a massive dataset, that can develop photorealistic images and generate the image as envisioned by humans. This project primarily focuses on using a vast dataset and generating the human visualized image.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Telangana",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "The crop's product, or yield, is influenced by the seed's  quality as well as external climatic elements like temperature and humidity. Because  different seeds have varied types of growth rates, it is crucial for seed researchers to  analyse the stages of seed germination. Thus, keeping track on seed germination improves  crop productivity. It is difficult and labor-intensive to evaluate a seed's quality solely by  visual inspection, and the results won't be very accurate.YOLOv5 algorithm used in monitoring the germination of the seeds with the help of jetson nano.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "The current transportation system in a city is inefficient and causes congestion and increased pollution. The city is looking for a solution to improve mobility and reduce traffic congestion and make pollution free.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "The prevalence of snake bites among farmers and tribal people poses a significant threat to their lives and livelihoods. In the absence of an efficient snake detection system, these individuals are at risk of encountering venomous snakes while working in farm fields, leading to severe health consequences and even death. A reliable and efficient snake detection system that can identify and alert farmers in real-time is needed to prevent snake bites and ensure their safety.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "The problem is how to protect video content from unauthorized use and distribution, and the challenge is to develop an efficient and robust video watermarking algorithm that can maintain the video's quality while protecting its ownership and integrity.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "The project aims to prevent drunk driving and improve road safety by designing and implementing an alcohol detection and engine locking system that can accurately detect alcohol levels and prevent the engine from starting if the alcohol level is above the legal limit. ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "The world is in the need of constant supply of energy and is majorly dependent on non-renewable sources causing extensive damage to the environment. To overcome this problem, we came up with the idea of  footstep power generation where piezoelectric sensors are employed to convert the pressure exerted when walking or running into electrical energy of usable form. By doing this, we can overcome the problem of energy scarcity as well, and prevent exploiting nature as this is a sustainable source of energy.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Thermal Star is a high class material suggestor which is used to suggest the perfect material for the given application. ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "To help investors and entrepreneurs connect and work together more effectively",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "To predict the prices of grains.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Underwater Mine and Obstacle detection using IOT.",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Unmasking Online Aggravators",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Usage of composite roof sheets to overcome the environmental hazards of asbestos roof sheets ",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "Vehicle Detection Using Panoramic 3D LiDAR",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Verification of url is valid or not",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Vision Enhancement and face detection in hazy weather condition.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "VLSI Architecture for Fractional Order Adaptive Filter",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "We all know how impactful a good yoga session can be. The trend these days is that people are preferring online yoga sessions more and more after the pandemic. But technically, these sessions are not immersive like live yoga sessions.  So, in order to cover the gap we are trying to build a machine learning model which will assist the users while they practice yoga in the online mode. Our model will track the user's yoga footage and will analyse various aspects and it will finally give them some feedback regarding what changes can be made and what is fine.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Weighted adaptive spectrum sensing in cognitive vehicular networks for dynamic&nbsp;spectrum&nbsp;access",
   "Clg": "VNR VJIET",
   "Type": 0
  },
  {
   "PS": "When an employee seeks to change an organization, there is an uncertainty because the recruiter for the new organization is unaware of the employee's current employment status, which calls into doubt on the credibility of the employee. The reliability of an employer is shown from his\/her certificates with their experience in previous companies. These certifications and proof of experience are stored in traditional applications inside a central database, so there is a way to change the data. This can be avoided by using systems like blockchain. As it provides a viable option to store this certificate data across blockchain.",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "White blood cell classification usinh deep learning ",
   "Clg": "VNR VJIET",
   "Type": 1
  },
  {
   "PS": "Wireless charging and battery management system for electric vehicles",
   "Clg": "VNR VJIET",
   "Type": 0
  },
 
 ]




export default class ProblemStatemets extends Component {


  
  render() {
    return (
      <div style={{height:'100vh'}}>
        <div>

       
        Problem Statements
        <br/>

        Green - Hardware
        <br/>
        Violet - Software
        </div>


  { ps.map((clubEvent, id) => {
                            
                           
                            return (
                             
                               <PSCard key={id} {...clubEvent}/>
                               
                               
                                 );
                            
                            
                            
                          })}



</div>
    )
  }
}
