import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import Logo from '../images/logobg.jpg';
import LogoImg from '../images/logoImg.png';

 function NavBar()  {

    const { pathname } = useLocation();

    let navigate = useNavigate();
    function logOut(){
      sessionStorage.removeItem('Auth Token');
      sessionStorage.removeItem('Mail');
      navigate('/login')
    }
    const isDash = (pathname!=='/login')?1:0;

    return (
        <Navbar sticky="top" style={{background: "#000000"}} expand="lg">
        <Container fluid>
          
            <Navbar.Collapse  style={{color:"white",fontFamily:"Salba"}}  id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '200px' ,color:"white"}}
              
              navbarScroll
            >
             { /*
              <Nav.Link style={{color:"white",fontFamily:"Salba"}} href="">Home</Nav.Link>
              <Nav.Link  style={{color:"white",fontFamily:"Salba"}} href="">About</Nav.Link>
              <NavDropdown  style={{color:"white",fontFamily:"Salba"}}  title="Test " id="navbarScrollingDropdown">
                <NavDropdown.Item  href="">Action</NavDropdown.Item>
                <NavDropdown.Item href="">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
    */}
            </Nav>
            <Form className="d-flex" style={{paddingRight:50}}>
             
              <Button href='https://forms.gle/KdWjyk7E8L4MPBq87' target="_blank" variant="outline-success">Register Now</Button>
              <Button href='/login' style={{marginLeft:20}} variant="outline-success">Login</Button>
           
            </Form>

            <Form className="d-flex" style={{paddingRight:50}}>
             
             
              <Button style={{marginLeft:20}} variant="outline-success">Sign Out</Button>
           
            </Form>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  
}

export default NavBar;