import { Card } from "react-bootstrap";

function AboutUs() {
    return (
     
      <Card style={{backgroundColor:'lightgray',marginTop:15,marginBottom:35,marginLeft:5,marginRight:5,padding:5}}>

    

     
      <div class="xyz">
      <Card style={{backgroundColor:'blanchedalmond',marginTop:15,marginBottom:35,marginLeft:10,marginRight:10,padding:30}}>
        <h1 class="abc">OPEN HOUSE CONTEST – 2023</h1>
        <p class="abc">
        Open House is one of the prolific ideas that VNR VJIET has come up with. It is an open arena for all the students to come up with their creative latent skills. Creativity involves two stages, i.e. conceptualization and actualization. The perfect blend of vision and blueprint of desired outcome leads to successful projects.
        </p>
        </Card>

        <Card style={{backgroundColor:'lightcyan',marginTop:15,marginBottom:35,marginLeft:5,marginRight:5,padding:10}}>

    
        <div >
        
        <table id="pqr">
  <thead>
    <tr>
      <th>TECHNOLOGY AREAS</th>
      <th>APPLICATION DOMAINS</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>•	Signal Processing, Wireless Communication</td>
      <td>•	Defence</td>
    </tr>
    <tr>
      <td>•	Embedded Control, VLSI</td>
      <td>•	Automotive</td>
    </tr>
    <tr>
      <td>•	Speech Technology, Image Processing</td>
      <td>•	Agriculture</td>
    </tr>
    <tr>
      <td>•	Computer Graphics, Computer Vision</td>
      <td>•	Water Management</td>
    </tr>
    <tr>
      <td>•	Computer Networks, Software Defined Networks, Information And Network Security</td>
      <td>•	Healthcare</td>
    </tr>
    <tr>
      <td>•	Data Mining, Information retrieval and extraction</td>
      <td>•	Energy & Power</td>
    </tr> <tr>
      <td>•	Robotics</td>
      <td>•	Energy & Power</td>
    </tr>
    <tr>
      <td>•	High Voltage Engineering</td>
      <td>•	E-Commerce, E-Learning, E-Governance</td>
    </tr>
    <tr>
      <td>•	Microgrids</td>
      <td>•	Gaming</td>
    </tr>
    <tr>
      <td>•	Power electronics, Power Systems</td>
      <td>•	Industrial</td>
    </tr>
    <tr>
      <td>•	Renewable Energy</td>
      <td>•	Information Technology & Telecommunications</td>
    </tr>
    <tr>
      <td>•	Electrical Machines</td>
      <td>•	Construction</td>
    </tr>
    <tr>
      <td>•	Automobile</td>
      <td>•	Domestic</td>
    </tr>
    <tr>
      <td>•	Manufacturing, Design</td>
      <td>•	Biometrics</td>
    </tr>
    <tr>
      <td>•	Material Science</td>
      <td></td>
    </tr>
    <tr>
      <td>•	Additive Manufacturing</td>
      <td></td>
    </tr>
    <tr>
      <td>•	Hydraulics</td>
      <td></td>
    </tr>
    <tr>
      <td>•	Thermal Engineering</td>
      <td></td>
    </tr>
    <tr>
      <td>•	Welding</td>
      <td></td>
    </tr>
    <tr>
      <td>•	Structures</td>
      <td></td>
    </tr>
  </tbody>
</table>
</div>
</Card>

<Card style={{backgroundColor:'darkorchid',marginTop:15,marginBottom:35,marginLeft:5,marginRight:5,padding:10}}>

<h4 class="qwerty" style={{color:'white'}}>Judgement Process</h4>
        <p  style={{color:'white'}}>
        •	There are three judgement rounds.</p>
        <p  style={{color:'white'}}>
•	The first two rounds of Judgement are conducted by the internal Judges. Internal Judges are going to evaluate each round.</p>
<p  style={{color:'white'}}>•	The last round i.e. third round of Judgement is conducted by the External jury. And the External Jury are going to evaluate the third round.</p>
<p  style={{color:'white'}}>•	The team with the most number of points after all the three judgement rounds are going to win the Open House Contest.</p>
</Card>
<Card style={{backgroundColor:'lightcyan',marginTop:15,marginBottom:35,marginLeft:5,marginRight:5,padding:10}}>

<h4 class="qwerty">Idea Development</h4>
        <p>
        •		Teams must outline the fundamental concept of their execution plan.</p>
        <p>
•	Teams must submit an abstract and a breakdown of the individual parts used in the design.</p>
</Card>
<Card style={{backgroundColor:'aquamarine',marginTop:15,marginBottom:35,marginLeft:5,marginRight:5,padding:10}}>

<h4 class="qwerty">Level of Demonstration</h4>
<p>•	Teams must show a thorough demonstration of the creative solution they have offered.</p>
<p>•	Teams must explain the solution's usability and scalability. </p>
<p>*The technical team will test the team after the demonstration.*</p>
</Card>
<Card style={{backgroundColor:'darkslateblue',opacity:0.8 ,marginTop:15,marginBottom:35,marginLeft:5,marginRight:5,padding:10}}>

<h4 class="abc"  style={{color:'white'}}>Standard Rules</h4>
<p style={{color:'white'}} >•	Once the project contest starts, teams are not permitted. If there are any disagreements, the judges' or organisers' judgement will be considered final and binding on everyone. The registered participants will be informed of any changes, if any by postal mail.</p>
<p  style={{color:'white'}}>•	Each team will receive a Team ID following registration, which should be used for future references. They will also be provided with the login credentials to access the dashboard.</p>
<p style={{color:'white'}}>•	Before participating in the project competition, the prototype should be ready.</p>
<p style={{color:'white'}}>•	In submitting this form, there is no registration fee required for VNR students.</p>
<p style={{color:'white'}}>•	Once our team has verified your project, you should pay the fee.</p>
<p style={{color:'white'}}>•	There should be 2 to 4 persons in a team.</p>
<p style={{color:'white'}}>•	The registration fee for non-VNR students is Rs.500 per team.</p>
<p style={{color:'white'}}>•	You must enter all your team details before submitting the forms.</p>
<p style={{color:'white'}}>• Teams must report before 8:00am to venue on April 15th 2023</p>

</Card>
<h4 class="qwerty"></h4>
      <table id="pqr">
  <thead>
    <tr>
      <th>Date and time</th>
      <th>Venue</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>April 15th 2023</td>
      <td>VNR VJIET, Hyderabad</td>
    </tr>
    </tbody>
    </table>
      </div>

      </Card>
      
    );
  }
  
  export default AboutUs;