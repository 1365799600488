import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import HomePage from './components/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import { AuthProvider } from './authContext';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import ProblemStatemets from './components/ProblemStatemets';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer/Footer';
import AddParticipant from './components/AddTeam';
import AdminVerify from './components/AdminVerify';
import AddPS from './AddPS';
import Upload from './components/Upload';
import Results from './components/Results';
import PDFViewer  from './components/PdfView';
function App() {

  
  return (
    <div className="App">
     <NavBar/>


     <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/home" element={<HomePage/>}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/dashboard" element={<Dashboard/>}/>
      <Route path="/problemStatements" element={<ProblemStatemets/>}/>
      <Route path="/aboutUs" element={<AboutUs/>}/>
      <Route path="/addTeam" element={<AddParticipant/>}/>
      <Route path="/addPS" element={<AddPS/>}/>
      <Route path="/adminVerify" element={<AdminVerify/>}/>
      <Route path="/uploadPs" element={<Upload/>}/>
      <Route path="/results" element={<Results/>}/>
      <Route path="/report" element={<PDFViewer/>}/>
      </Routes>

     
      
      {/*
      <Router>
          <AuthProvider>
            <Routes>
              <PrivateRoute exact path="/dashboard" component={HomePage} />
            
              <Route path="/" component={HomePage} />
              <Route path="/login" component={LoginPage} />
              
            </Routes>
          </AuthProvider>
        </Router>
        */}
      
     
      
       
        
        
    </div>
    
  );
}

export default App;
