import React, { Component } from 'react'

export default class PdfViewer extends Component {
  render() {
    return (
      <div style={{height:"100vh"}}> 
      <h1>Open House 2k23 Report</h1>
         <iframe
        title="Open House"
        src="https://docs.google.com/gview?a=v&pid=explorer&chrome=false&api=true&embedded=true&srcid=1Scc3WRh-4PDiAf3FaknBfew6d7BNKXFW"
        width="70%"
        height="80%"
        frameBorder="0"
        scrolling="auto"
      ></iframe>
      </div>
    )
  }
}
