import React from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
}
from 'mdb-react-ui-kit';

import Logo from '../images/logo1.png';
import { app } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'


 
function LoginPage() {

  let navigate = useNavigate();
  useEffect(() => {
      let authToken = sessionStorage.getItem('Auth Token')

      if (authToken) {
          navigate('/dashboard')
      }

     
  }, [])

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function login()   {
    const authentication = getAuth();

    signInWithEmailAndPassword(authentication, email, password).then((response) => {
      console.log(response)

      if(email.includes("@openhouseadmin.in")){
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
        sessionStorage.setItem('Mail', email.replace("@openhouseadmin.in","").toLowerCase());
        navigate("/adminVerify");
      }
      else{
        sessionStorage.setItem('Type',"Participant")
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
        sessionStorage.setItem('Mail', email.replace("@openhouse.in",""));
        toast.success("Login Success")
        
        navigate("/dashboard");
      }
     
  }).catch((error) => {
    if(error.code === 'auth/wrong-password'){
      toast.error('Please check the Password');
      
    }
    if(error.code === 'auth/user-not-found'){
      toast.info('Please check the Email');
    }
  })
}

  return (
    <div style={{height:'100vh'}}>
       
    <MDBContainer className="my-5" >

      <MDBCard>
        <MDBRow className='g-0'>

          <MDBCol md='6'>
            <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg' alt="login form" className='rounded-start w-100'/>
          </MDBCol>

          <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>

              <div className='d-flex flex-row mt-2' style={{alignContent:'center',alignItems:'center'}}>
                <MDBCardImage fas src={Logo} style={{ color: '#ff6219',width:100,height:100 }}/>
                <span style={{paddingLeft:20}} className="h1 fw-bold mb-0">Open House </span>
              </div>

              <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Sign into your account</h5>

                <MDBInput wrapperClass='mb-4' label='Email address' id='mail' type='email' onChange={(e) => setEmail(e.target.value)}  size="lg"/>
                <MDBInput wrapperClass='mb-4' label='Password' id='pass' type='password' onChange={(e) => setPassword(e.target.value)} size="lg"/>

              <MDBBtn className="mb-4 px-5" color='dark' onClick={login} size='lg'>Login</MDBBtn>
             {/*  <a className="small text-muted" href="#!">Forgot password?</a>
              <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <a href="#!" style={{color: '#393f81'}}>Register here</a></p>
*/}
              <div className='d-flex flex-row justify-content-start'>
                <a className="small text-muted me-1">Terms of use.</a>
                <a href="/privacy" className="small text-muted">Privacy policy</a>
              </div>

            </MDBCardBody>
          </MDBCol>

        </MDBRow>
      </MDBCard>

    </MDBContainer>

    <ToastContainer />
    </div>
  );

 
}

export default LoginPage;