import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';

const pathname = window.pathname;

export default function Footer() {
  return (
    <MDBFooter  style={{visibility:pathname === '/login'?'hidden':'visible',overflow:'hidden'}} bgColor='primary' className='text-white text-center text-lg-left'>
      <MDBContainer className='p-4'>
        <MDBRow>
          <MDBCol lg='6' md='12' className='mb-4 mb-md-0'>
           {/* <h5 className=''>Website Developed by Team Iot Club VNR</h5>*/ 
}
              <a style={{color:'black'}} href='/dashboard'><h3>Go to Dashboard</h3></a>
            <p>
             
            </p>
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase'>Quick Links</h5>

            <ul className='list-unstyled mb-0'>
              <li>
                <a href='https://forms.gle/KdWjyk7E8L4MPBq87' target={'_blank'} className='text-white'>
                  Register Now
                </a>
              </li>
              <li>
                <a href='/Login' className='text-white'>
                  Login
                </a>
              </li>

              <li>
                <a href='/ca' className='text-white'>
                  Campus Ambassdor 
                </a>
              </li>
              
            </ul>
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase mb-0'>Links</h5>

            <ul className='list-unstyled'>
              <li>
                <a href='/aboutUs' className='text-white'>
                 About Open House 2k23
                </a>
              </li>
              <li>
                <a href='/problemStatements' className='text-white'>
                 Problem Statements
                </a>
              </li>
              
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-white' href='https://vnropenhouse.in/'>
          VNR Open House
        </a>
      </div>
    </MDBFooter>
  );
}