import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Banner from './Banner';
import Upload from './Upload';

export default class HomePage extends Component {
  render() {
    return (

      <div style={{height:'100vh'}}>


        <div>
        <div style={{display:"block",alignContent:'center'}} className="d-lg-none navbar-text aligh-items-center">
       
        
             <Button href='https://forms.gle/KdWjyk7E8L4MPBq87' target="_blank" variant="outline-success">Register Now</Button>
             <Button href='/login' style={{marginLeft:20}} variant="outline-success">Login</Button>
             <div style={{overflow:'hidden',whiteSpace:'nowrap',marginTop:20}} >
              


<Banner/>
</div>
        </div>
       



        </div>

        <div className='d-none d-lg-block' style={{width:900,overflow:'hidden',whiteSpace:'nowrap',alignContent:'center',alignItems:'center',margin:'auto',marginTop:20}} >


<Banner/>
</div>
<div className="button-row">
<a href='/report'> <button  className="button secondary">View Report</button></a>
     <a href='/results'> <button  className="button secondary">Results</button></a>
    </div>




      </div>
    )
  }
}
