import { doc, getDoc } from "firebase/firestore"; 

import { getDb } from "./db.mjs"

const collection_name = "Participants"

export const findOne = async id => {
   
    const d = await getDoc(doc(getDb(), collection_name, id));
   // console.log(d);
    if(d.data().N1 === undefined){
        return 0;
    }
    return d.data();
}