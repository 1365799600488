import React from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCardTitle, MDBContainer, MDBRow } from 'mdb-react-ui-kit';

export default function TeamCard({...data}) {
  return (
   

    <section>

   <div className='d-lg-none' style={{whiteSpace:'nowrap'}}>

 
        
        <MDBCard  style={{margin:40,visibility:data.N1==null?'hidden':'visible',width:'75%',fontSize:14,margin:'auto',backgroundColor:'burlywood',display:'inline-block', verticalAlign:'middle',color:'black', marginBottom:20}}>
            
      <MDBCardHeader style={{fontSize:16,whiteSpace:'nowrap'}}><b>{data.id==1? "Team Leader":"Team Memeber "}{data.id==1? "":data.id-1}</b></MDBCardHeader>
      <MDBCardBody>
      <MDBCardTitle style={{fontSize:21}}>Name: {data.N1}</MDBCardTitle>
        <MDBCardTitle style={{fontSize:21}}>Roll No: {data.R1}</MDBCardTitle>
        <MDBCardTitle style={{fontSize:12,whiteSpace:'nowrap'}}> Branch: {data.B1}</MDBCardTitle>
        <MDBCardTitle style={{fontSize:21}}>Year: {data.Y1}</MDBCardTitle>
        <MDBCardText style={{fontSize:21}}>Email Id: {data.E1}</MDBCardText>
        <MDBCardText style={{fontSize:21}}>Mobile Number: {data.M1}</MDBCardText>
        <MDBCardText style={{fontSize:21}}>College: {data.C1}</MDBCardText>

      </MDBCardBody>
    </MDBCard>
    </div>
    <div className='d-none d-lg-block'>

 
        
        <MDBCard  style={{margin:40,visibility:data.N1==null?'hidden':'visible',width:'80%',fontSize:14,margin:'auto',backgroundColor:'burlywood',display:'inline-block', verticalAlign:'middle',color:'black', marginBottom:20}}>
            
      <MDBCardHeader style={{fontSize:16,whiteSpace:'pre-wrap'}}><b>{data.id==1? "Team Leader":"Team Memeber "}{data.id==1? "":data.id-1}</b></MDBCardHeader>
      <MDBCardBody>
        <MDBCardTitle style={{fontSize:21}}>Name: {data.N1}</MDBCardTitle>
        <MDBCardTitle style={{fontSize:21}}>Roll No: {data.R1}</MDBCardTitle>
        <MDBCardTitle style={{fontSize:21}}> Branch: {data.B1}</MDBCardTitle>
        <MDBCardTitle style={{fontSize:21}}>Year: {data.Y1}</MDBCardTitle>
        <MDBCardText style={{fontSize:21}}>Email Id: {data.E1}</MDBCardText>
        <MDBCardText style={{fontSize:21}}>Mobile Number: {data.M1}</MDBCardText>
        <MDBCardText style={{fontSize:21}}>College: {data.C1}</MDBCardText>
      
      </MDBCardBody>
    </MDBCard>
    </div>
    </section>
    
  )
}
