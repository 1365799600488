import { useState } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";
import { MDBContainer } from "mdb-react-ui-kit";
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateS } from "../services/statusUp.mjs";

function Upload() {

    let navigate = useNavigate();
    const [res, setRes] = useState([]);
    const type = sessionStorage.getItem('Type');
    //console.log(type)
    
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
        let canUpload = sessionStorage.getItem('upload')
        
       // let mail = sessionStorage.getItem('Mail')
       // setMailId(mail);

        if (authToken) {
            if(canUpload!=="2"|| canUpload!=2){
                navigate('/dashboard')
            }
           
            
           
        }

        if (!authToken) {
            navigate('/login')
        }
    }, [])


  const [progress, setProgress] = useState(0);
  const formHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    const size = file.size // it's in bytes
    console.log(file.name)
    //added a max file size limit of 100Kb
    if (size/1024 > 5000) {
      alert("file size must not be greater than to 5Mb")
      //console.log( alert("file size must not be greater than to "))
      return
    }
    else{
        uploadFiles(file);
    }
   
  };

  const uploadFiles = (file) => {
    //
    if (!file) return;
    const sotrageRef = ref(storage, `Poster/${sessionStorage.getItem('tId').replace(".","")+".pdf"}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          if(updateS(sessionStorage.getItem("Mail"))){
            navigate("/dashboard");
          }
         


          
        });
      }
    );
  };

  return (
    <div >
<MDBContainer className="my-5" >



      <form onSubmit={formHandler}>
        <input type="file" accept="application/pdf"  className="input" />
        <button type="submit">Upload</button>
      </form>
      <hr />
      <h2>Uploading done {progress}%</h2>
     
      </MDBContainer>

      
    </div>
  );
}

export default Upload;