import React from 'react'
import { Button } from 'react-bootstrap'
import Timer from './Timer'

export default function Results() {
  return (
    <div>
    
      <div style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:30}}>
     
      <Button href='https://forms.gle/WAhTXhXAS4syvu8f6' target='_blank' style={{marginTop:10}}>Do Give the Feedback</Button>

      </div>

     <div class="card" style={{margin:15,color:'black',fontWeight:'bold',fontSize:30,backgroundColor:'green'}}>
  <h5 class="card-header" style={{color:'white',fontSize:40}}>1st Place</h5>
  <div class="card-body">
  <div class="card" style={{marginTop:5}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230230</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: PATIENT OBLIGATION PERCEIVER USING EYE GESTURES</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Software</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: VNR VJIET</p>
    
  </div>
</div>
<div class="card" style={{marginTop:20}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230104</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: Wireless Automation Assistance for Paralyzed and Elders</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: VNR VJIET</p>
    
  </div>
</div>
  </div>
  </div>

  <div class="card" style={{margin:15,color:'black',fontWeight:'bold',fontSize:30,backgroundColor:'green'}}>
  <h5 class="card-header" style={{color:'white',fontSize:40}}>2nd Place</h5>
  <div class="card-body">
  <div class="card" style={{marginTop:5}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230106</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: Electric cycle for easy mobility in heavy traffic</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: VNR VJIET</p>
    
  </div>
</div>
<div class="card" style={{marginTop:20}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230133</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: A machine that saves time to do writing and drawing .It can be also be upgraded for PCB printing</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: AAR MAHAVEER ENGINEERING COLLEGE </p>
    
  </div>
</div>
  </div>
  </div>


  <div class="card" style={{margin:15,color:'black',fontWeight:'bold',fontSize:30,backgroundColor:'green'}}>
  <h5 class="card-header" style={{color:'white',fontSize:40}}>3rd Place</h5>
  <div class="card-body">
  <div class="card" style={{marginTop:5}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230171</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: Autonomous Robot for Mapping and Navigation</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: VNR VJIET</p>
    
  </div>
</div>
<div class="card" style={{marginTop:20}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230164</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: Traditional irrigation system </h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: CMR Engineering college </p>
    
  </div>
</div>
  </div>
  </div>

  <div class="card" style={{margin:15,color:'black',fontWeight:'bold',fontSize:30,backgroundColor:'green'}}>
  <h5 class="card-header" style={{color:'white',fontSize:40}}>Appreciation Prizes</h5>
  <div class="card-body">
  <div class="card" style={{marginTop:5}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230120</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: Identification and display of depth of rainfall through mobile application including temperature and humidity using tipping bucket rain gauge station and sensors</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>College: VNR VJIET</p>
    
  </div>
</div>
<div class="card" style={{marginTop:20}}>
  <h5 class="card-header" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:16}}>Team Id: OH230161</h5>
  <div class="card-body">
    <h5 class="card-title" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:24}}>Title: Debit Card for Children</h5>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>Domain: Hardware</p>
    <p class="card-text" style={{marginTop:20,color:'black',fontWeight:'bold',fontSize:20}}>School: ZPHS Nehru Nagar,Qutbullapur</p>
    
  </div>
</div>
  </div>
  </div>


{/*
  <Timer/>
      <Button href='https://instagram.com/vnr_openhouse?igshid=YmMyMTA2M2Y=' target='_blank' style={{marginTop:20}}>Follow us on Open House Insta Page</Button> 
      <br/>
<Button href='https://instagram.com/iotclub_vnr?igshid=YmMyMTA2M2Y=' target='_blank' style={{marginTop:20}}>Follow us on IoT Club Insta Page</Button>
*/}
 <Button href='https://forms.gle/WAhTXhXAS4syvu8f6' target='_blank' style={{marginTop:10,marginBottom:30}}>Do Give the Feedback</Button>

    </div>


  )
}
