import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Img1 from '../images/1.png'
import Img2 from '../images/2.png'
import Img3 from '../images/2.png'

export default class Banner extends Component {
    render() {
        return (
          

            
            <Carousel showThumbs={false} infiniteLoop autoPlay style={{height:20}} >
                
                <div style={{height:10}} >
                    <img  src={Img1}/>
                   <a href='https://forms.gle/KdWjyk7E8L4MPBq87' target="_blank"> <p className="legend">Open House 2k23</p></a>
                </div>
                <div>
                    <img  src={Img3} />
                    <a href='https://forms.gle/5LbZQST97gVtrMtp8' target="_blank"> <p className="legend">Apply Now</p></a>
                </div>
                <div>
                    <img src={Img2} />
                    <a href='https://forms.gle/KdWjyk7E8L4MPBq87' target="_blank"> <p className="legend">Open House 2k23</p></a>
                </div>

              
                
            </Carousel>
          
        );
    }
}




