import React from 'react'

export default function PSCard(data) {
  return (
    <div className="card1" style={{marginTop:20,backgroundColor:data.Type==1?'blueviolet':'forestgreen'}}>
      <h2 style={{color:'white'}}>Problem Statement</h2>
      <p style={{color:'white'}}>{data.PS}</p>
     
      <span style={{color:'white'}}>{data.Clg}</span>
    </div>
  )
}
