import React from 'react';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRadio
}
from 'mdb-react-ui-kit';
import {  doc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { getDb } from "../services/db.mjs";


function AddParticipant() {

    const [tSize, setTSize] = useState(2);
    const [tYear, setTYear] = useState("");
    const [name1, setName1] = useState("");
    const [name2, setName2] = useState("");
    const [name3, setName3] = useState("");
    const [name4, setName4] = useState("");
    const [roll1, setRoll1] = useState("");
    const [roll2, setRoll2] = useState("");
    const [roll3, setRoll3] = useState("");
    const [roll4, setRoll4] = useState("");
    const [mn1, setMn1] = useState("");
    const [mn2, setMn2] = useState("");
    const [mn3, setMn3] = useState("");
    const [mn4, setMn4] = useState("");
    const [mail1, setMail1] = useState("");
    const [mail2, setMail2] = useState("");
    const [mail3, setMail3] = useState("");
    const [mail4, setMail4] = useState("");
    const [br1, setBr1] = useState("");
    const [br2, setBr2] = useState("");
    const [br3, setBr3] = useState("");
    const [br4, setBr4] = useState("");
    const [clg1, setClg1] = useState("");
    const [clg2, setClg2] = useState("");
    const [clg3, setClg3] = useState("");
    const [clg4, setClg4] = useState("");
    const [tSec, setTSec] = useState("");
    const [tYear1, setTYear1] = useState("");
    const [tSec1, setTSec1] = useState("");
    const [tYear2, setTYear2] = useState("");
    const [tSec2, setTSec2] = useState("");
    const [tYear3, setTYear3] = useState("");
    const [tSec3, setTSec3] = useState("");
    const [ps, setPs] = useState("");
    const [refN, setRefN] = useState("");
    const [clgT, setClgT] = useState(1);
    const [loadingT, setLoadingT] = useState(0);


    let navigate = useNavigate();
    const [res, setRes] = useState([]);
    const type = sessionStorage.getItem('Type');
    console.log(type)
    
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
       // let mail = sessionStorage.getItem('Mail')
       // setMailId(mail);

        if (authToken) {
            if(type!=="Coordinator")
            navigate('/home')
        }

        if (!authToken) {
            navigate('/login')
        }
    }, [])

    function Submit(){
      setLoadingT(1);
        if(name1 != "" &&name2 != "" &&(name3 != "" || tSize<3) &&(name4 != "" || tSize<4) ){

            if(roll1 != "" &&roll2 != "" &&(roll3 != "" || tSize<3) &&(roll4 != "" || tSize<4) ){

                if(mn1 != "" &&mn2 != "" &&(mn3 != "" || tSize<3) &&(mn4 != "" || tSize<4) ){

          if(mail1 != "" &&mail2 != "" &&(mail3 != "" || tSize<3) &&(mail4 != "" || tSize<4) ){

            if(br1 != "" &&br2 != "" &&(br3 != "" || tSize<3) &&(br4 != "" || tSize<4) ){
                if(clg1 != undefined &&clg2 != undefined &&(clg3 != undefined || tSize<3) &&(clg4 != "" || tSize<4) ){

        if(tYear != "" &&tYear1 != "" &&(tYear2 != "" || tSize<3) &&(tYear3 != "" || tSize<4) ){

        if(tSec != "" &&tSec1 != "" &&(tSec2 != "" || tSize<3) &&(tSec3 != "" || tSize<4) ){


            if(ps!=undefined){
              


              const resForm ={

                status:0,
                ps:ps,
                name:name1,
                roll:roll1,
                clg:clg1,
                year:tYear,
                sec : tSec,
                mail: mail1,
                ref: refN,
                tS: tSize,
                clgType: clgT,
                addedBy:sessionStorage.getItem("Mail"),
                tId:"Will be Updated Soon..!",
                p1:{
                  name:name2,
                  roll:roll2,
                  clg:clg2,
                  year:tYear1,
                  sec : tSec1,
                  mail: mail2,
        
                },

               

                p2:{
                    name:name3,
                   
                roll:roll3,
                clg:clg3,
                year:tYear2,
                sec : tSec2,
                mail: mail3,
        
                },
                p3:{
                    name:name4,
                   
                    roll:roll4,
                    clg:clg4,
                    year:tYear3,
                    sec : tSec3,
                    mail: mail4,
        
                },
            };

            if(tSize==3){
                resForm.p2 = null;  
                resForm.p3 = null;  
            }

            if(tSize==4){
             
              resForm.p3 = null;  
          }

// Your Firebase SDK Initialization code here

        

        const docRef = doc(getDb(), "Participants", mail1.replace("@gmail.com","") );

           

                    setDoc(docRef, resForm)
                    .then(() => {
                        toast.success("Participant has been added successfully");
                        setLoadingT(0);
                        navigate("/dashboard");
                    })
                    .catch(error => {
                      setLoadingT(0);
                        toast.error(error);
                    })





                

            }else{
              setLoadingT(0);
                toast.error("Enter Problem Statement");
            }

        }

        else{
          setLoadingT(0);
            toast.error("Sections are not Selected Properly");
        }
    }
    else{
      setLoadingT(0);
        toast.error("Years are not Selected Properly");
    }
        }
        else{
          setLoadingT(0);
            toast.error("Colleges are not Entered Properly");
        }
    }
    else{
      setLoadingT(0);
        toast.error("Branches are not Entered Properly");
    }
    } else{
      setLoadingT(0);
        toast.error("Mails are not Entered Properly");
    }
    } else{
      setLoadingT(0);
        toast.error("Mobile Numbers are not Entered Properly");
    }
    }else{
      setLoadingT(0);
        toast.error("Roll Numbers are not Entered Properly");
    }
}
else{
  setLoadingT(0);
    toast.error("Names are not Entered Properly");
}
    }

  const onOptionChange = e => {
    teamSize = e.target.value;
    console.log(teamSize);
    
    setTSize(e.target.value);
  }

  const onTYChange = e => {  
    setTYear(e.target.value);
  }

  const onTSChange = e => {  
    setTSec(e.target.value)
  }

  const onTYChange1 = e => {  
    setTYear1(e.target.value);
  }

  const onTSChange1 = e => {  
    setTSec1(e.target.value)
  }
  const onTYChange2 = e => {  
    setTYear2(e.target.value);
  }

  const onTSChange2 = e => {  
    setTSec2(e.target.value)
  }

  const onTYChange3 = e => {  
    setTYear3(e.target.value);
  }

  const onTSChange3 = e => {  
    setTSec3(e.target.value)
  }
  const setPS = e => {  
    setPs(e.target.value)
  }
  const onClgType = e => {  
    setClgT(e.target.value)
  }
    var teamSize=2;

  return (
    <MDBContainer fluid>

      <MDBRow className='justify-content-center align-items-center m-5'>

        <MDBCard>
          <MDBCardBody className='px-4'>

            <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5">Open House - Add Team</h3>

            <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='First Name' size='lg' id='name1' type='text' onInput={e => setName1(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Roll Number' size='lg' id='roll1' type='text' onInput={e => setRoll1(e.target.value)}/>
              </MDBCol>

            </MDBRow>
             <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mobile Number' size='lg' id='phone1' type='text' onInput={e => setMn1(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mail Id' size='lg' id='mail1' type='text' onInput={e => setMail1(e.target.value)}/>
              </MDBCol>

            </MDBRow>
             <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Branch' size='lg' id='b1' type='text' onInput={e => setBr1(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='College' size='lg' id='c1' type='text' onInput={e => setClg1(e.target.value)}/>
              </MDBCol>

            </MDBRow>
            <MDBRow>

            <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Year: </h6>
                <MDBRadio name='inlineRadio' id='y11'  value='1st Year' label='1st Year' inline onChange={onTYChange}/>
                <MDBRadio name='inlineRadio' id='y11'  value='2nd Year' label='2nd Year' inline onChange={onTYChange}/>
                <MDBRadio name='inlineRadio' id='y12'  value='3rd Year' label='3rd Year' inline onChange={onTYChange} />
                <MDBRadio name='inlineRadio' id='y13'  value='4th Year' label='4th Year' inline onChange={onTYChange}/>
              </MDBCol>

              <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Section: </h6>
                <MDBRadio name='inlineRadios' id='inlineRadio1' value='1' label='1' inline onChange={onTSChange}/>
                <MDBRadio name='inlineRadios' id='inlineRadio2' value='2' label='2' inline onChange={onTSChange}/>
                <MDBRadio name='inlineRadios' id='inlineRadio3' value='3' label='3' inline onChange={onTSChange}/>
                <MDBRadio name='inlineRadios' id='inlineRadio3' value='4' label='4' inline onChange={onTSChange}/>
              </MDBCol>
            

            </MDBRow>

            <MDBRow>
            <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Team Size: </h6>
                <MDBRadio name='inlineRadiot' id='t22' value='2' label='2' inline  onChange={onOptionChange} checked={tSize==2} />
                <MDBRadio name='inlineRadiot' id='t33' value='3' label='3' inline   onChange={onOptionChange} checked={tSize==3}/>
                <MDBRadio name='inlineRadiot' id='t44' value='4' label='4' inline  onChange={onOptionChange} checked={tSize==4}  />
              </MDBCol>
              <MDBCol md='6'>
                  <MDBInput wrapperClass='mb-4' label='Problem Statement' size='lg' id='ps' type='text' onChange={setPS}/>
              </MDBCol>

             

            </MDBRow>

           
        <MDBRow>
       
              <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">College Type </h6>
                <MDBRadio name='clgYT' id='t225' value='1' label='VNR VJIET' inline checked={clgT ==1}  onChange={onClgType} />
                <MDBRadio name='clgYT' id='t335' value='2' label='Non VNR' inline  checked={clgT ==2} onChange={onClgType}/>
                 </MDBCol> 

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Referal' size='lg' id='refNo' type='text' onInput={e => setRefN(e.target.value)}/>
             
              </MDBCol>

        </MDBRow>
          
          </MDBCardBody>
        </MDBCard>

      </MDBRow>






      <MDBRow className='justify-content-center align-items-center m-5'>
       

        <MDBCard>
          <MDBCardBody className='px-4'>
                <h4>Team Member 1</h4>


 <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='First Name' size='lg' id='name2' type='text' onInput={e => setName2(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Roll Number' size='lg' id='roll2' type='text' onInput={e => setRoll2(e.target.value)}/>
              </MDBCol>

            </MDBRow>

          <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mobile Number' size='lg' id='phone2' type='text'onInput={e => setMn2(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mail Id' size='lg' id='mail2' type='text' onInput={e => setMail2(e.target.value)}/>
              </MDBCol>

            </MDBRow>
             <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Branch' size='lg' id='b2' type='text' onInput={e => setBr2(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='College' size='lg' id='c2' type='text' onInput={e => setClg2(e.target.value)}/>
              </MDBCol>

            </MDBRow>


             <MDBRow>

            <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Year: </h6>
                <MDBRadio name='inlineRadioy31' id='y11'  value='1st Year' label='1st Year' inline onChange={onTYChange1}/>
                <MDBRadio name='inlineRadioy31' id='y22' value='2nd Year' label='2nd Year' inline onChange={onTYChange1} />
                <MDBRadio name='inlineRadioy31' id='y23' value='3rd Year' label='3rd Year' inline  onChange={onTYChange1}/>
                <MDBRadio name='inlineRadioy31' id='y24' value='4th Year' label='4th Year' inline onChange={onTYChange1} />
              </MDBCol>

              <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Section: </h6>
                <MDBRadio name='inlineRadios221' id='s21' value='1' label='1' inline onChange={onTSChange1}/>
                <MDBRadio name='inlineRadios221' id='s22' value='2' label='2' inline onChange={onTSChange1}/>
                <MDBRadio name='inlineRadios221' id='s23' value='3' label='3' inline onChange={onTSChange1}/>
                <MDBRadio name='inlineRadios221' id='s24' value='4' label='4' inline onChange={onTSChange1}/>
              </MDBCol>
            

            </MDBRow>
          </MDBCardBody>

          </MDBCard>


      </MDBRow>






      <MDBRow style={{height:tSize>2?null:0,overflow:'hidden'}}  className='justify-content-center align-items-center m-5'>
       

        <MDBCard>
          <MDBCardBody className='px-4'>
                <h4>Team Member 2</h4>


 <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='First Name' size='lg' id='name3' type='text' onInput={e => setName3(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Roll Number' size='lg' id='roll3' type='text' onInput={e => setRoll3(e.target.value)}/>
              </MDBCol>

            </MDBRow>

          <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mobile Number' size='lg' id='phone3' type='text' onInput={e => setMn3(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mail Id' size='lg' id='mail3' type='text' onInput={e => setMail3(e.target.value)}/>
              </MDBCol>

            </MDBRow>
             <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Branch' size='lg' id='b3' type='text' onInput={e => setBr3(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='College' size='lg' id='c3' type='text' onInput={e => setClg3(e.target.value)}/>
              </MDBCol>

            </MDBRow>


             <MDBRow>

            <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Year: </h6>
                <MDBRadio name='inlineRadiot11' id='y32' value='1st Year' label='1st Year' inline onChange={onTYChange2} />
                <MDBRadio name='inlineRadiot11' id='y32' value='2nd Year' label='2nd Year' inline onChange={onTYChange2} />
                <MDBRadio name='inlineRadiot11' id='y33' value='3rd Year' label='3rd Year' inline onChange={onTYChange2} />
                <MDBRadio name='inlineRadiot11' id='y34' value='4th Year' label='4th Year' inline onChange={onTYChange2} />
              </MDBCol>

              <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Section: </h6>
                <MDBRadio name='inlineRadios11' id='s31' value='1' label='1' inline onChange={onTSChange2} />
                <MDBRadio name='inlineRadios11' id='s32' value='2' label='2' inline onChange={onTSChange2}/>
                <MDBRadio name='inlineRadios11' id='s33' value='3' label='3' inline onChange={onTSChange2} />
                <MDBRadio name='inlineRadios11' id='s34' value='4' label='4' inline onChange={onTSChange2} />
              </MDBCol>
            

            </MDBRow>
          </MDBCardBody>

          </MDBCard>


      </MDBRow>






      <MDBRow style={{height:tSize>3?null:0,overflow:'hidden'}}  className='justify-content-center align-items-center m-5'>
       

        <MDBCard>
          <MDBCardBody className='px-4'>
                <h4>Team Member 3</h4>


 <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='First Name' size='lg' id='name4' type='text' onInput={e => setName4(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Roll Number' size='lg' id='roll4' type='text' onInput={e => setRoll4(e.target.value)}/>
              </MDBCol>

            </MDBRow>

          <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mobile Number' size='lg' id='phone4' type='text' onInput={e => setMn4(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Mail Id' size='lg' id='mail4' type='text' onInput={e => setMail4(e.target.value)}/>
              </MDBCol>

            </MDBRow>
             <MDBRow>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='Branch' size='lg' id='b4' type='text' onInput={e => setBr4(e.target.value)}/>
              </MDBCol>

              <MDBCol md='6'>
                <MDBInput wrapperClass='mb-4' label='College' size='lg' id='c4' type='text' onInput={e => setClg4(e.target.value)}/>
              </MDBCol>

            </MDBRow>


             <MDBRow>

            <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Year: </h6>
                <MDBRadio name='inlineRadiot511' id='y42' value='1st Year' label='1st Year' inline onChange={onTYChange3}/>
                <MDBRadio name='inlineRadiot511' id='y42' value='2nd Year' label='2nd Year' inline onChange={onTYChange3}/>
                <MDBRadio name='inlineRadiot511' id='y43' value='3rd Year' label='3rd Year' inline onChange={onTYChange3}/>
                <MDBRadio name='inlineRadiot511' id='y44' value='4th Year' label='4th Year' inline onChange={onTYChange3}/>
              </MDBCol>

              <MDBCol md='6' className='mb-4'>
                <h6 className="fw-bold">Section: </h6>
                <MDBRadio name='inlineRadio211' id='s41' value='1' label='1' inline onChange={onTSChange3} />
                <MDBRadio name='inlineRadio211' id='s42' value='2' label='2' inline onChange={onTSChange3}/>
                <MDBRadio name='inlineRadio211' id='s43' value='3' label='3' inline onChange={onTSChange3}/>
                <MDBRadio name='inlineRadio211' id='s44' value='4' label='4' inline onChange={onTSChange3}/>
              </MDBCol>
            

            </MDBRow>
          </MDBCardBody>

          </MDBCard>


      </MDBRow>




<div style={{alignContent:'center',alignItems:'center', margin:'auto', height:loadingT?null:0,overflow:'hidden'}}>
<ReactLoading  className='loadingcenter' type={"spin"} color={'black'} height={60} width={60} />


</div>







      <MDBBtn className='mb-4' size='lg' onClick={Submit}>Submit</MDBBtn>
      <ToastContainer />

    </MDBContainer>
  );
}

export default AddParticipant;